import React from "react";

function Optimized() {
  return (
    <div className="container optimized_sec">
      <div className="row">
        <div className="col-md-6">
          <div className="position-relative">
            <div className="position-absolute over-div" />
            <img
              src={require("../img/opti.png")}
              alt="logo-1"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-md-6 my-auto">
          <div className="w-lg-80 ms-lg-auto">
            <h5 className="w-heading">Optimum Content can be generated automatically</h5>
            <p className="w-sub-p my-4 my-md-3">
              The AI Writing model will then analyze the input and generate a
              unique and creative piece of content based on the user's
              specifications.
            </p>
            <a href="#" className="btn btn-signup start-btn rounded-0">
            Try it For Free
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Optimized;
