import React from "react";

function How_our() {
  return (
    <div className="container how_our_sec">
      <div className="row">
        <div className="col-md-6">
          <div>
            <h5 className="w-heading">How our Ai Writer works</h5>
            <p className="w-sub-p my-lg-4 ">
              Writings.Ai is fast and easy to use. Just write the question and
              we will give you the answer. You will be excited.
            </p>
            <div className="p-lg-5 p-2 p-md-3">
              <p className="par mb-lg-5 mb-3 mb-md-4">
                1. Choose the article that you want to copy. This feature
                streamlines the process of using AI Writing, making it simple
                and intuitive for users to accomplish their desired task.
              </p>
              <p className="par mb-lg-5 mb-3 mb-md-4">
                2. Enter the Specifics of your products or business. The AI
                Writing model will then analyze the input and generate a unique
                and creative piece of content based on the user's specifications
              </p>
              <p className="par mb-lg-5 mb-3 mb-md-4">
                3. See how Ais is used to generate content and articles. Use
                Writings.Ai editor to rewrite paragraphs and polish up sentences
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature-form p-lg-5 w-lg-80 ms-lg-auto p-3">
            <form name="feature" method="post" className="form border-1">
              <div className="mb-4">
                <label>Choose features</label>
                <div className="input-group">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Blog Section writing </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <label>Section Topic</label>
                <div className="input-group">
                  <textarea
                    class="form-control"
                    aria-label="With textarea"
                    placeholder="Role of Ai writer in the world of copywriting and its future"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label>Section Keywords (optional)</label>
                <div className="input-group">
                  <textarea
                    class="form-control"
                    aria-label="With textarea"
                    placeholder="Ai writer, blog generator, best writing software in the world"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label>Number of Variants</label>
                <div className="input-group">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="1">1 Variants</option>
                    <option value="2">2 Variants</option>
                    <option value="3">3 Variants</option>
                  </select>
                </div>
              </div>
              <div className="mb-lg-5 mb-4 mb-md-4">
                <label>Creativity level</label>
                <div className="input-group">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Optimal</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div>
                <button className="w-100 btn btn-signup rounded-0" type="submit">Write For Me</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default How_our;
