import React from "react";

function Logo() {
  return (
    <div className="container logo_sec">
      <div>
        <h4 className="logo-heading mb-4 text-center">
          Trusted by 1,000,000+ Marketers At Companies Including..
        </h4>
        <div className="row">
          <div className="col-md-3">
            <div>
              <img src={require("../img/logo1.png")} alt="logo-1" className="img-fluid"/>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <img src={require("../img/logo2.png")} alt="logo-2" className="img-fluid"/>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <img src={require("../img/logo3.png")} alt="logo-2" className="img-fluid"/>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <img src={require("../img/logo4.png")} alt="logo-4" className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logo;
