import React from "react";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import img1 from '../img/card-img-1.png'
import img2 from '../img/card-img-2.png'
import img3 from '../img/card-img-3.png'

function Testimonial() {
    return (
        <section className="testimonial_sec">
            <div className="container">
                <div className="row text-center">
                    <h6 className="text-blue">TESTIMONIAL</h6>
                    <h4 className="w-heading mb-4">What our Client say about us</h4>
                </div>
                <div className="row">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2.5,
                                spaceBetween: 20,
                            },
                            768:{
                                slidesPerView: 1.8,
                                spaceBetween: 30,
                                centeredSlides: true,
                            },
                            1024: {
                                slidesPerView: 3.5,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="testimonial_card text-center mb-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="card_img">
                                        <img src={img1} />
                                    </div>
                                    <div className="card_text">
                                        <p className="mt-2">They are the best! I am impressed and grateful</p>
                                        <h5 className="mt-3 mb-0 fw-bolder">Nils Tom</h5>
                                        <span>Freelancer</span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial_card text-center mb-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="card_img">
                                        <img src={img2} />
                                    </div>
                                    <div className="card_text">
                                        <p className="mt-2">Awesome. I am pleased. The service is so swift and nice</p>
                                        <h5 className="mt-3 mb-0 fw-bolder">Sandra U.</h5>
                                        <span>Vlogger</span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial_card text-center mb-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="card_img">
                                        <img src={img3} />
                                    </div>
                                    <div className="card_text">
                                        <p className="mt-2">Their Customer Support is so quick to action. I was attended to quickly</p>
                                        <h5 className="mt-3 mb-0 fw-bolder">Clinton Adv.</h5>
                                        <span>Journalist</span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial_card text-center mb-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="card_img">
                                        <img src={img2} />
                                    </div>
                                    <div className="card_text">
                                        <p className="mt-2">Awesome. I am pleased. The service is so swift and nice</p>
                                        <h5 className="mt-3 mb-0 fw-bolder">Sandra U.</h5>
                                        <span>Vlogger</span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial_card text-center mb-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="card_img">
                                        <img src={img3} />
                                    </div>
                                    <div className="card_text">
                                        <p className="mt-2">Their Customer Support is so quick to action. I was attended to quickly</p>
                                        <h5 className="mt-3 mb-0 fw-bolder">Clinton Adv.</h5>
                                        <span>Journalist</span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div >
        </section >
    )
}

export default Testimonial;