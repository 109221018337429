import React from "react";
import SlideImg from "../img/slider.png";

function Slider() {
  return (
    <div className="container home_slider_sec">
      <div className="row">
        <div className="col-md-6 mt-lg-5 order-text  mt-5 mt-lg-0 mt-md-0">
          <div className="h-100">
            <h5 className="sub-heading text-blue">WELCOME TO WRITINGS.Ai</h5>
            <h5 className="heading my-3">
              Best Ai Writer for Creating Business Ideas and other important
              Ideas.
            </h5>
            <p className="sub-p mb-3">
              Our advanced language models will help you generate creative
              content, translate text, summarize information, and more.
            </p>
            <a href="#" className="btn btn-signup start-btn rounded-0">
              Start Writing For Free
            </a>
          </div>
        </div>
        <div className="col-md-6 order-img">
          <div>
            <img src={SlideImg} alt="slider-img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
