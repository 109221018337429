import React from "react";
import icon1 from "../img/blog-icon.png"
import icon2 from "../img/business-icon.png"
import icon3 from "../img/cover-icon.png"
import icon4 from "../img/twitter-icon.png"
import icon5 from "../img/google-icon.png"
import icon6 from "../img/seo-icon.png"
import icon7 from "../img/seo-icon-title.png"
import icon8 from "../img/video-icon.png"
import icon9 from "../img/idea-icon.png"
import arrow from '../img/arrow.png'

function Offer() {
  return (
    <div className="container offer">
      <div className="row offer_row">
        <div className="col-md-12">
          <div className="text-center">
            <h5 className="text-blue">WHAT WE OFFER</h5>
            <h6 className="w-heading mb-lg-5 mb-md-4 mb-3">We are here to write for you</h6>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon1} alt="blog-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Blog Ideas & Outline</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Quickly Generate AI-Powered Blog Ideas & Outlines</p>
                <span className="offer-icon">Try it for Free <img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon2} alt="business-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Business Ideas</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Unlock Your Business Potential with AI Generated Ideas</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon3} alt="cover-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Cover Letter</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Create an AI Generated Cover Letter in Minutes!</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon4} alt="twitter-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Facebook & Twitter Ads</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Generate High Quality AI & Twitter Ads for your business</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon5} alt="google-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Google Search Ads</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Create unique and engaging google ads with AI tech</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon6} alt="seo-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">SEO Meta Description</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">Automate Your SEO Content with AI-Generated Meta Descriptions</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon7} alt="seo-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">SEO Meta Title</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">AI-Powered Video Description Writing for Maximum Engagement</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon8} alt="video-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Video Description</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2">AI-Powered Video Description Writing for Maximum Engagement</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="bg-white p-lg-3 p-md-3 p-2 mb-3 mb-md-0 rounded-3">
            <div className="d-flex align-items-center">
              <div>
                <img src={icon9} alt="video-icon" />
              </div>
              <div className="ms-3">
                <p className="offer-heading pt-md-2">Video Idea</p>
                <p className="offer-sub m-0 mb-lg-2 mb-2 ">Generate Video Idea Ideas with AI Writing Assistance</p>
                <span className="offer-icon">Try it for Free<img src={arrow} className="ms-1"/></span>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Offer;
