import React from "react";

function Who_we() {
  return (
    <div className="container who_we_sec">
      <div className="row">
        <div className="col-md-6 mt-md-3">
          <div>
            <img
              src={require("../img/About Us.png")}
              alt="logo-1"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="who_we w-lg-80 ms-lg-auto mt-4 mt-md-0">
            <h5 className="text-blue">WHO WE ARE</h5>
            <h2 className="w-heading my-3">
              Writing the Best Content that you will be excited about.
            </h2>
            <p className="w-sub-p my-lg-5">
              Our advanced language models will help you generate creative
              content, translate text, summarize information, and more. and you
              will be excited about our results
            </p>
            <a href="#" className="btn btn-signup start-btn rounded-0">
            Discover More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Who_we;
