import React from "react";
import Icon from '../img/plan-true.png'
import Icon1 from '../img/plan-false.png'

function Pricing_Plan() {
    return (
        <section className="pricing_plan">
            <div className="container">
                <div className="row">
                    <div className="text-center">
                        <h4 className="w-heading mb-4">Pricing Plans</h4>
                    </div>
                    <div className="col-lg-4 col-md-6 mx-md-auto mt-md-3">
                        <div className="plan_box_design">
                            <div className="plan_text">
                                <h5 className="mb-3 text-black">Free Plan</h5>
                                <p>Generate 2k characters per month on free plan. Upgrade as you scale.</p>
                                <p className="text-black price_num"> <span className="text-black">$0</span>/monthly</p>
                                <h6 className="plan_list mb-3">What’s included</h6>
                                <p className="text-black"><img src={Icon} className="me-3" />Generate 2000 AI Words / month</p>
                                <p className="text-black"><img src={Icon} className="me-3" />Access 12+ Features</p>
                                <p className="text-black"><img src={Icon1} className="me-3" />Priority email & chat support 0</p>
                            </div>
                            <div className="mt-4 mt-auto">
                                <button type="button" className="subscribe_btn">Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mx-md-auto mt-md-3">
                        <div className="plan_box_design">
                            <div className="plan_text">
                                <h5 className="mb-3 text-black">Saver Plan</h5>
                                <p>Generate 100k* characters per month Writings.Ai offers one of the most cost-effective solutions in the market.</p>
                                <p className="text-black price_num"> <span className="text-black">$29.99</span>/monthly</p>
                                <h6 className="plan_list mb-3">What’s included</h6>
                                <p className="text-black"><img src={Icon} className="me-3" />Generate 10000 AI Words / month</p>
                                <p className="text-black"><img src={Icon} className="me-3" />Access 12+ Features</p>
                                <p className="text-black"><img src={Icon} className="me-3" />Priority email & chat support 1</p>
                            </div>
                            <div className="mt-4 mt-auto">
                                <button type="button" className="subscribe_btn">Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mx-md-auto mt-md-3">
                    <div className="plan_box_design">
                            <div className="plan_text">
                                <h5 className="mb-3 text-black">Saver Plan</h5>
                                <p>Generate 100k* characters per month Writings.Ai offers one of the most cost-effective solutions in the market.</p>
                                <p className="text-black price_num"> <span className="text-black">$29.99</span>/monthly</p>
                                <h6 className="plan_list mb-3">What’s included</h6>
                                <p className="text-black"><img src={Icon} className="me-3" />Generate 10000 AI Words / month</p>
                                <p className="text-black"><img src={Icon} className="me-3" />Access 12+ Features</p>
                                <p className="text-black"><img src={Icon} className="me-3" />Priority email & chat support 1</p>
                            </div>
                            <div className="mt-4 mt-auto">
                                <button type="button" className="subscribe_btn">Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing_Plan;