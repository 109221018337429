import "./App.css";
import How_our from "./component/How_our";
import Logo from "./component/Logo";
import Navbar from "./component/Navbar";
import Offer from "./component/Offer";
import Optimized from "./component/Optimized";
import Slider from "./component/Slider";
import Who_we from "./component/Who_we";
import Testimonial from "./component/Testimonial";
import Pricing_Plan from "./component/Pricing_plan";
import Footer from "./component/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Slider />
      <Logo />
      <Who_we />
      <How_our />
      <Optimized />
      <Offer />
      <Testimonial/>
      <Pricing_Plan/>
      <Footer/>
    </>
  );
}

export default App;
