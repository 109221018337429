import React from "react";

function Footer(){
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-2">
                        <div className="list_heading">
                            <h5 className="mb-3 mb-lg-4 mb-md-3 text-white">Company</h5>
                            <ul className="p-0 m-0">
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Home</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Blog</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Terms</a></li>
                                <li className=" mb-lg-4 mb-md-4 footer_list"><a href="#" className="text-white">Privacy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="list_heading ms-md-4 ps-md-2">
                            <h5 className="mb-3 mb-lg-4 mb-md-3 text-white">Features</h5>
                            <ul className="p-0 m-0">
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Blog Idea & Outline</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Business Idea</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Cover Letter</a></li>
                                <li className=" mb-lg-4 mb-md-4 footer_list"><a href="#" className="text-white">Google Ads</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 p-md-0">
                        <div className="list_heading">
                            <h5 className="mb-3 mb-lg-4 mb-md-3 text-white">Support</h5>
                            <ul className="p-0 m-0">
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Register</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Login</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Contact Us</a></li>
                                <li className=" mb-lg-4 mb-md-4 footer_list"><a href="#" className="text-white">Terms & Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="list_heading">
                            <h5 className="mb-3 mb-lg-4 mb-md-3 text-white">Resources</h5>
                            <ul className="p-0 m-0">
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Pricing</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">Blogs</a></li>
                                <li className=" mb-2 mb-lg-3 mb-md-2"><a href="#" className="text-white">FAQS</a></li>
                                <li className=" mb-lg-4 mb-md-4 footer_list"><a href="#" className="text-white">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <p className="mb-0 text-white mt-3 mt-lg-4 copyright_text">Copyright Writings.Ai © 2023. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;